<template>
  <div v-loading="loading">
    <el-card shadow="never">
      <div slot="header">
        <span class="iconfont icon-EpgManage">&nbsp;EPG管理</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="dialogAddEpg=true">新增EPG</el-button>
          <el-button
            plain
            size="small"
            @click="batchDelEpg"
            v-show="dataLineSelections.length>0"
          >批量删除</el-button>
        </div>
        <div>
          <el-input
            v-model="search"
            placeholder="请输入用户账号、名称"
            suffix-icon="el-icon-search"
            style="width:300px"
            size="small"
            @change="initLoad"
          ></el-input>
        </div>
      </div>
      <el-table
        ref="table"
        :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        size="mini"
        @selection-change="selectionLineChangeHandle"
        row-key="id"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column prop="channelName" label="节目名称" width="150"></el-table-column>
        <el-table-column prop="tvId" label="TVID" width="150"></el-table-column>
        <el-table-column prop="epgKeyWord" label="关键词" show-overflow-tooltip></el-table-column>
        <el-table-column prop="epgData" label="API数据" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openEditEpg(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="onSubmitDelEpg(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 添加EPG -->
    <el-dialog
      :visible.sync="dialogAddEpg"
      :close-on-click-modal="false"
      width="30%"
      @closed="closeDialogAddEpg"
    >
      <span slot="title">新增EPG</span>
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="节目名称"
          prop="channelName"
          :rules="{ required: true, message: '请输入节目名称', trigger: 'blur' }"
        >
          <el-input v-model="form.channelName" placeholder="请输入节目名称"></el-input>
        </el-form-item>
        <el-form-item
          label="TVID"
          prop="channelName"
          :rules="{ required: true, message: '请输入TVID', trigger: 'blur' }"
        >
          <el-input v-model="form.tvId" placeholder="请输入TVID"></el-input>
        </el-form-item>
        <el-form-item
          label="关键词"
          prop="channelName"
          :rules="{ required: true, message: '请输入关键词', trigger: 'blur' }"
        >
          <el-input v-model="form.epgKeyWord" type="textarea" rows="6" placeholder="请输入关键词"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddEpg=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddEpg">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改EPG -->
    <el-dialog
      :visible.sync="dialogEditEpg"
      :close-on-click-modal="false"
      width="30%"
    >
      <span slot="title">编辑EPG</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="节目名称"
          prop="channelName"
          :rules="{ required: true, message: '请输入节目名称', trigger: 'blur' }"
        >
          <el-input v-model="editForm.channelName" placeholder="请输入节目名称"></el-input>
        </el-form-item>
        <el-form-item
          label="TVID"
          prop="channelName"
          :rules="{ required: true, message: '请输入TVID', trigger: 'blur' }"
        >
          <el-input v-model="editForm.tvId" placeholder="请输入TVID"></el-input>
        </el-form-item>
        <el-form-item
          label="关键词"
          prop="channelName"
          :rules="{ required: true, message: '请输入关键词', trigger: 'blur' }"
        >
          <el-input v-model="editForm.epgKeyWord" type="textarea" rows="6" placeholder="请输入关键词"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditEpg=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditEpg">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getEpgApi, addEpgApi, editEpgApi, delEpgApi } from "../../api/data";
import date from "../../utils/date";
export default {
  data() {
    return {
      loading:true,
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      search: "", //查询条件
      list: [],
      dialogAddEpg: false, //添加窗口
      dialogEditEpg: false, //修改窗口
      form: {},
      editForm: {},
      dataLineSelections: [] //勾选列表
    };
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getEpgApi().then(res => {
        this.list = res.data;
        this.loading=false;
      });
    },
    //添加EPG
    onSubmitAddEpg() {
      addEpgApi(this.form).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
          this.dialogAddEpg = false;
        }
      });
    },
    //添加窗口关闭事件
    closeDialogAddEpg() {
      this.$refs["form"].resetFields();
    },
    //打开编辑窗口
    openEditEpg(obj) {
      this.editForm = { ...obj };
      this.dialogEditEpg = true;
    },
    //修改EPG
    onSubmitEditEpg() {
      editEpgApi(this.editForm).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
          this.dialogEditEpg = false;
        }
      });
    },

    //批量删除EPG
    batchDelEpg() {
      this.$confirm("此操作将永久删除该EPG, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delEpgApi({ ids: this.dataLineSelections }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
            this.$refs.table.clearSelection();
          }
        });
      });
    },
    //删除EPG
    onSubmitDelEpg(obj) {
      this.$confirm("此操作将永久删除该EPG, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const epgArray = [];
        epgArray.push(obj.epgId);
        delEpgApi({ ids: epgArray }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //表格多选框选中事件
    selectionLineChangeHandle(val) {
      const selectionList = [];
      for (let i = 0; i < val.length; i++) {
        selectionList.push(val[i].epgId);
      }
      this.dataLineSelections = selectionList;
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    //时间格式化
    dateFormat(row) {
      return date.dateFormat(row.lastUpdateTime);
    }
  }
};
</script>
<style lang='scss'>
</style>